<template>
  <div class="history-session-list">
    <session-list-item
      v-for="session in sessionHistory"
      :key="session.id"
      :session="session"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import SessionListItem from "./sessionListItem";

export default {
  components: { SessionListItem },

  computed: {
    ...mapState(["sessionHistory"]),
  },
};
</script>
