<template>
  <div class="nav-bar">
    <router-link :to="dashboard" class="nav-bar__link">
      <span class="icon icon__dashboard nav-bar__icon" />
      <span class="nav-bar__title">Dashboard</span>
    </router-link>
    <router-link :to="history" class="nav-bar__link">
      <span class="icon icon__history nav-bar__icon" />
      <span class="nav-bar__title">History</span>
    </router-link>
    <router-link  v-if="!isCareTaker" :to="characteristics" class="nav-bar__link">
          <span class="icon icon__characteristics nav-bar__icon" />
          <span class="nav-bar__title">Profile</span>
    </router-link>
    <router-link  v-if="!isCareTaker" :to="recommendations" class="nav-bar__link">
      <span class="icon icon__recommendations nav-bar__icon" />
      <span class="nav-bar__title">Tips</span>
    </router-link>
    <router-link v-if="!isCareTaker"  :to="settings" class="nav-bar__link">
      <span class="icon icon__settings nav-bar__icon" />
      <span class="nav-bar__title">Settings</span>
    </router-link>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { ROUTE_PROFILE_HISTORY, ROUTE_PROFILE_SETTINGS,
  ROUTE_PROFILE_CHARACTERISTICS, ROUTE_PROFILE_RECOMMENDATIONS,
  ROUTE_PROFILE_DASHBOARD  } from "@/constants";

export default {
  data: () => ({
    history: { name: ROUTE_PROFILE_HISTORY },
    dashboard: { name : ROUTE_PROFILE_DASHBOARD },
    characteristics: { name: ROUTE_PROFILE_CHARACTERISTICS },
    recommendations: { name: ROUTE_PROFILE_RECOMMENDATIONS },
    settings: { name: ROUTE_PROFILE_SETTINGS }
  }),

  computed: {
    ...mapState({
      isCareTaker: ({ user }) => user.caretaker,
    }),
  },
};
</script>
