<template>
  <div class="profile">
    <v-header class="profile-header">
      <template v-slot:record>
        <button
          v-if="!isCareTaker" 
          class="button button_secondary button_sm"
          @click="recordHandler"
        >
          Record sample
        </button>
      </template>
      <nav-bar class="profile__navbar" />
    </v-header>
    <div class="container container_sm profile__container">
      <slot />
    </div>
  </div>
</template>

<script>
import vHeader from "@/components/header";
import navBar from "./navBar";
import { ROUTE_PROFILE_RECORD } from "@/constants";
import { mapState } from "vuex";

export default {
  name: "profilePage",

  components: { vHeader, navBar },

  computed: {
    ...mapState({
      isCareTaker: ({ user }) => user.caretaker,
    }),
  },
  methods: {
    recordHandler() {
      this.$router.push({ name: ROUTE_PROFILE_RECORD }, null, () => {});
    },
  },
};
</script>
