<template>
  <card class="danger">
    <p><strong>Something wrong</strong></p>
    <p>
      Please, try to open this page later or
      <a
        href="#"
        @click.prevent="contactUsHandler"
        class="white link-contact-us"
      >
        contact us.
      </a>
    </p>
  </card>
</template>

<script>
import Card from "@/components/card";
import { POPUP_CONTACT_US } from "@/constants";

export default {
  components: { Card },

  inject: {
    showModal: { default: () => {} },
  },

  methods: {
    contactUsHandler() {
      this.showModal(POPUP_CONTACT_US);
    },
  },
};
</script>
