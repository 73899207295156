<template>
  <disclaimer>
    <p>
      Select a session below to see your PRELIMINARY Lung Function Report™.
    </p>
    <p>
      Report accuracy for you and others will improve with each sample
      collected. <a href="#" @click.prevent="clickHandler">Record</a> samples
      regularly. Encourage friends to donate samples.
    </p>
  </disclaimer>
</template>

<script>
import Disclaimer from "@/components/profile/disclaimer";
import { mapState } from "vuex";
import { ROUTE_PROFILE_RECORD } from "@/constants";

export default {
  components: { Disclaimer },
  computed: {
    ...mapState({
      isCareTaker: ({ user }) => user.caretaker,
    }),
  },
  methods: {
    clickHandler() {
      if(!this.isCareTaker)
        this.$router.push({ name: ROUTE_PROFILE_RECORD }, null, () => {});
    },
  },

  data: () => ({
    recordRoute: { name: ROUTE_PROFILE_RECORD },
  }),
};
</script>
