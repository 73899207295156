<template>
  <div class="socials">
    <alert v-if="isAlertShowed" @hide="hideAlert">
      <img src="@/assets/images/copy.png" alt="copy" class="alert__icon" slot="icon" />
      <div class="socials__copied">Link copied to clipboard</div>
    </alert>
    <div class="socials__list">
      <div class="socials__item btn-copy" @click="copy">
        <img src="@/assets/images/favicon.png" alt="Copy link" class="logo logo_xs socials__logo" />
        <span>Copy Link</span>
      </div>
      <a
        class="socials__item socials__item_fb"
        target="_blank"
        rel="noopener noreferrer"
        :href="fb"
        @click="shareHandler('facebook')"
      >
        <img src="@/assets/images/facebook.svg" alt="Copy link" class="logo logo_xs socials__logo" />
        <span>Share on Facebook</span>
      </a>
      <a
        class="socials__item socials__item_tw"
        target="_blank"
        rel="noopener noreferrer"
        :href="tw"
        @click="shareHandler('twitter')"
      >
        <img src="@/assets/images/twitter.svg" alt="Copy link" class="logo logo_xs socials__logo" />
        <span>Share on Twitter</span>
      </a>
    </div>
  </div>
</template>

<script>
import { copyToClipboard } from "@/modules/utils";
import Alert from "@/components/alert";
import { saveLog } from "@/modules/logger";
import { SHARE_SITE_URL } from "@/constants"

export default {
  components: { Alert },

  data: () => ({
    url: window.location.origin,
    isAlertShowed: false,
  }),

  computed: {
    fb() {
      return `https://www.facebook.com/sharer/sharer.php?u=${SHARE_SITE_URL}`;
    },
    tw() {
      return `https://twitter.com/intent/tweet?text=${SHARE_SITE_URL}`;
    },
  },

  methods: {
    copy() {
      saveLog("share-link-copied");
      copyToClipboard(SHARE_SITE_URL);
      this.showAlert();
    },

    shareHandler(platform) {
      saveLog("share-social-media", { platform });
    },

    showAlert() {
      this.isAlertShowed = true;
    },

    hideAlert() {
      this.isAlertShowed = false;
    },
  },
};
</script>
