<template>
  <card class="not-complited">
    <div class="not-complited__title">
      <h3 class="warning-message">
        No complete samples yet
      </h3>
    </div>
    <div class="not-complited__text">
      Record a sample now to create a Lung Performance Report™
    </div>
    <template v-slot:footer>
      <button class="button button_secondary button_md" @click="clickHandler">
        <span>record sample</span>
        <span class="icon icon__arrow icon__arrow_right icon__arrow_angle" />
      </button>
    </template>
  </card>
</template>

<script>
import Card from "@/components/card";
import { ROUTE_PROFILE_RECORD } from "@/constants";

export default {
  components: { Card },

  methods: {
    clickHandler() {
      this.$router.push({ name: ROUTE_PROFILE_RECORD }, null, () => {});
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/basic/variables";

.not-complited {
  &__block {
    display: flex;
    align-items: center;
    padding: 1rem;
    background-color: $color-secondary-dark;
    border-radius: $ui-border-radius;
    cursor: pointer;
    transition: background $duration-normal;

    &:hover {
      background-color: lighten($color-secondary-dark, 5%);
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  &__icon {
    margin-left: auto;
  }

  &__title {
    text-align: center;

    .warning-message {
      font-size: $size-sm;
      padding-left: 30px;

      &::before {
        width: 22px;
        height: 22px;
      }
    }
  }
}
</style>
