<template>
  <profile-layout>
    <error v-if="error" />
    <div v-if="isLoaded">
      <not-complited v-if="!hasCompleted" />
      <disclaimer-text v-else />
      <session-list />
    </div>
  </profile-layout>
</template>

<script>
import { mapState, mapActions } from "vuex";
import ProfileLayout from "@/components/profile/profileLayout";
import NotComplited from "./components/notComplited";
import SessionList from "./components/sessionList";
import DisclaimerText from "./components/disclaimerText";
import Error from "@/components/error";
import logVisiting from "@/mixins/logVisiting";

import { STORE_GET_SESSION_HISTORY } from "@/constants";

export default {
  name: "sessionHistoryPage",

  components: {
    ProfileLayout,
    NotComplited,
    SessionList,
    DisclaimerText,
    Error
  },

  mixins: [logVisiting],

  data: () => ({
    error: false
  }),

  computed: {
    ...mapState(["sessionHistory"]),

    isLoaded() {
      return Boolean(this.sessionHistory);
    },

    hasCompleted() {
      return this.sessionHistory?.some(el => !el.partial);
    }
  },

  mounted() {
    this.getHistory();
  },

  methods: {
    ...mapActions([STORE_GET_SESSION_HISTORY]),

    getHistory() {
      this[STORE_GET_SESSION_HISTORY]().catch(this.errorHandler);
    },

    errorHandler() {
      this.error = true;
    }
  }
};
</script>
