<template>
  <card class="history-session-list__card">
    <div class="history-session-list__card-inner" :class="{ partial: isPartial }" @click="clickHandler">
      

      <div class="history-session-list__date">
        <span>{{ date.date }}</span>
        <span class="time">{{ date.time }}</span>
      </div>

      <div class="history-session-list__invalid" v-if="hasError">invalid session</div>
      <div class="history-session-list__partial" v-if="isPartial">partial session</div>
      <div class="history-session-list__icon" v-else>
        <span class="icon icon__arrow icon__arrow_right icon__arrow_angle" />
      </div>
    </div>
  </card>
</template>

<script>
import Card from "@/components/card";
import { dateFormatted } from "@/utils/date";
import {
ROUTE_PROFILE_HISTORY_DETAILED
} from "@/constants";

export default {
  components: { Card },

  props: {
    session: { type: Object, required: true }
  },

  computed: {
    isPartial() {
      return this.session.partial;
    },

    hasError() {
      return this.session.error_code > 0;
    },

    date() {
      const {
        isPartial,
        session: { start_date, end_date }
      } = this;

      const date = isPartial ? start_date : end_date;

      return {
        date: dateFormatted(date),
        time: dateFormatted(date, "time")
      };
    }
  },

  methods: {
    clickHandler() {
      if (this.isPartial) return;
      console.log("LPR goes here")
      console.log(this.session.id)

      this.$router.push(
        {
          name: ROUTE_PROFILE_HISTORY_DETAILED,
          params: { id: this.session.id },
        },
        null,
        () => {}
      );
    },
  },
};
</script>
