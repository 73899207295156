<template>
  <card class="disclaimer">
    <div class="disclaimer__text">
      <slot />
    </div>
    <social-networks class="disclaimer__social" />
  </card>
</template>

<script>
import Card from "@/components/card";
import SocialNetworks from "@/components/socialNetworks";

export default {
  components: { Card, SocialNetworks },
};
</script>
