<template>
  <div class="header-quiz">
    <div class="header-quiz__container container">
      <router-link 
        to="/app" class="header-quiz__logo"
        @click.native="logoClickHandler"
        >
        <span class="header-quiz__logo-image" />
      </router-link>
      <h1 class="header-quiz__title">{{ title }}</h1>
      <slot />
      <div v-if="$slots.record" class="header-quiz__record">
        <slot name="record" />
      </div>
      <div class="header-quiz__sign-in" v-if="!isAuth">
        <button
          @click="signInHandler"
          class="button button_blue header-quiz__sign-in-btn"
        >
          <div class="header-quiz__sign-in-text">Already enrolled?</div>
          Sign in
        </button>
      </div>
    </div>
    <v-progress v-if="hasProgress" :value="progress" />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import vProgress from "@/components/progress";
import { ROUTE_LOGIN } from "@/constants";
import { deregisterStream } from "@/api";

export default {
  components: { vProgress },

  computed: {
    ...mapGetters(["progress"]),
    ...mapState({
      userId: ({ user }) => user.user_id,
    }),

    isAuth() {
      return (
        Boolean(this.userId) // ||
        // this.$route.name === ROUTE_THANK_YOU_FOR_ENROLLING
      );
    },

    title() {
      return this.$route.meta.title || "";
    },

    hasProgress() {
      return this.$route.meta.hasProgressBar;
    },
  },
  methods: {
    signInHandler() {
      this.$router.push({ name: ROUTE_LOGIN });
    },
    logoClickHandler() {
      console.log("Logo pressed!")
      if(this.$store.state.StreamerRegistered) {
        deregisterStream(
          this.$store.state.user.session_id,
          this.$store.state.user.user_id,
        );
      }
    }
  },
};
</script>
